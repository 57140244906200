<template>
  <el-container style="display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 100vh; border: 2px solid #ddd; padding: 20px;">

    <!-- Aisino logo -->
    <div style="text-align: center; margin-bottom: 20px;">
      <img src="../../assets/logo.png" alt="Aisino Logo" style="max-width: 150px;">
    </div>

    <!-- 成功消息 -->
    <el-alert
        type="success"
        title="您的开票申请已受理"
        show-icon
        center
        style="margin-bottom: 20px; width: 80%;">
    </el-alert>

    <!-- 发票详情 -->
    <div style="text-align: center; margin-bottom: 20px;">
      <p>发票抬头：{{ invoiceInfo.title }}</p>
      <p>发票金额：{{ invoiceInfo.amount }}元</p>
    </div>

    <!-- 发票更改提示 -->
    <div style="text-align: center; margin-bottom: 15px;color: #555555">
      <p>发票信息将与2024-12-22日凌晨，由查看发票改为下载发票，望悉知！！！</p>
    </div>

    <!-- 如果发票下载地址不为空，显示下载发票按钮 -->
    <div v-if=invoiceLoaded>
      <el-button
          v-if="invoiceDownloadUrl&& invoiceDownloadUrl.trim() !== ''"
          type="primary"
          style="margin-bottom: 20px;"
          @click="downloadInvoice">下载发票
      </el-button>
      <!-- 否则显示查看发票按钮 -->
      <el-button
          v-else
          type="primary"
          style="margin-bottom: 20px;"
          @click="viewInvoice">查看发票
      </el-button>
    </div>
    <div v-else>
      <!-- 加载指示器或占位符 -->
      <p>正在加载发票信息...</p>
    </div>
    <!-- PDF 或 图片展示 -->
    <div v-if="pdf" style="width: 80%; height: 600px; margin-top: 20px;">
      <iframe v-if="isPDF" :src="pdf" width="100%" height="100%" style="border: none;"></iframe>

      <!-- 显示Base64图片 -->
      <img v-else :src="'data:image/jpeg;base64,' + pdf" width="100%" height="100%" alt="电子发票" />
    </div>
  </el-container>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      orderId: '', // 订单ID
      invoiceInfo: {
        title: '', // 发票抬头
        amount: '' // 发票金额
      },
      pdf: "", // 用于存储PDF或图像的Base64数据
      isPDF: true,// 用于判断是PDF还是图片
      invoiceDownloadUrl: "",// 发票下载地址
      invoiceLoaded: false // 用于控制是否加载完成
    };
  },
  methods: {
    fetchInvoiceInfo() {
      // 从URL参数获取发票信息
      const buyName = this.$route.query.buyName || '未知客户';
      const price = this.$route.query.price || '0.00';
      this.orderId = this.$route.query.orderId;
      this.invoiceInfo.title = decodeURIComponent(buyName);
      this.invoiceInfo.amount = decodeURIComponent(price);
    },
    async getInvoiceUrl() {// 获取发票下载地址
      const vm = this;
      try {
        // 调用后端接口获取下载发票的url
        const response = await axios.get(`https://kp.7-11bj.com/taxCloud/queryKpInfoByDDH?DDH=${vm.orderId}`);
        var results = response.data || {};
        if (results.data.pdfurl) {
          vm.invoiceDownloadUrl = results.data.pdfurl;
        }
        vm.pdf =results.data.pdf;
      }catch (error) {
        console.error('获取发票下载url失败:', error);
      }finally {
        vm.invoiceLoaded = true;
      }
    },
    async viewInvoice() {
      const vm = this;
      try {
        // 调用后端接口获取Base64编码的发票数据
        // const response = await axios.get(`https://kp.7-11bj.com/taxCloud/queryKpInfoByDDH?DDH=${vm.orderId}`);
        // const results = response.data || {};
        if (vm.pdf) {
          const base64Data = vm.pdf;
          // 检查Base64字符串的前缀，判断文件类型
          if (base64Data.startsWith("JVBERi0")) {
            // 如果是PDF
            vm.isPDF = true;
            vm.pdf = `data:application/pdf;base64,${base64Data}`;
          } else if (base64Data.startsWith("/9j/")) {
            // 如果是JPEG图片
            vm.isPDF = false;
            vm.pdf = base64Data; // 直接使用图片的Base64数据
          } else {
            alert('文件格式无法识别');
          }
        } else {
          alert('未找到发票文件');
        }
      } catch (error) {
        console.error('获取发票失败:', error);
      }
    },
    async downloadInvoice() {
      // 调用后端接口获取下载发票的url
      window.location.href = this.invoiceDownloadUrl;
    }
  },
  mounted() {
    this.fetchInvoiceInfo(); // 组件挂载时获取发票信息
    this.getInvoiceUrl();  // 获取发票下载地址
  }
};
</script>

<style scoped>
.el-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.el-button {
  width: 120px;
  height: 40px;
  font-size: 16px;
}

.el-alert {
  width: 100%;
  max-width: 500px;
}

iframe, img {
  border: none;
}
</style>
